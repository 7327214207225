import { useEffect } from "react"

const ExternalScripts = url => {
  useEffect(() => {
    googleAnalytics()
    hotJar()
  }, [])

  const googleAnalytics = () => {
    const script = document.createElement("script")
    script.type = "text/plain"
    script.dataset.type = "application/javascript"
    script.dataset.name = "googleAnalytics"

    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-178253149-1"
    script.async = true
    document.body.appendChild(script)

    const scriptConfig = document.createElement("script")
    scriptConfig.type = "text/plain"
    scriptConfig.dataset.type = "application/javascript"
    scriptConfig.dataset.name = "googleAnalytics"

    scriptConfig.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'UA-178253149-1', { 'anonymize_ip': true });
        `
    scriptConfig.async = true
    document.body.appendChild(scriptConfig)
  }

  const hotJar = () => {
    const script = document.createElement("script")
    script.type = "text/plain"
    script.dataset.type = "application/javascript"
    script.dataset.name = "hotJar"

    script.innerHTML = `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1925560,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `
    script.async = true
    document.body.appendChild(script)
  }
}

export default ExternalScripts
