/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = props => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }
        allGooglePlaces {
          nodes {
            result {
              address_components {
                long_name
                short_name
                types
              }
              international_phone_number
              opening_hours {
                periods {
                  open {
                    time
                  }
                  close {
                    time
                  }
                }
              }
              geometry {
                location {
                  lat
                  lng
                }
              }
            }
          }
        }

        img1: file(
          relativePath: { eq: "structured-data/local_business_1x1.jpg" }
        ) {
          id
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
        img2: file(
          relativePath: { eq: "structured-data/local_business_4x3.jpg" }
        ) {
          id
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
        img3: file(
          relativePath: { eq: "structured-data/local_business_16x9.jpg" }
        ) {
          id
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
      }
    `
  )

  let defaultProps = {
    title: `Dierda Finanzdienstleistung`,
    description: ``,
    logo: `https://signatur.dierda.de/logo.png`,
    lang: `de`,
    url: "https://dierda.de",
    path: "/",
    adress: {
      name: `Florian Dierda Finanzdienstleistung`,
      street:
        data.allGooglePlaces.nodes[0].result.address_components[1].long_name +
        " " +
        data.allGooglePlaces.nodes[0].result.address_components[0].long_name,
      city:
        data.allGooglePlaces.nodes[0].result.address_components[3].long_name,
      country:
        data.allGooglePlaces.nodes[0].result.address_components[6].long_name,
      countyCode:
        data.allGooglePlaces.nodes[0].result.address_components[6].short_name,
      plz: data.allGooglePlaces.nodes[0].result.address_components[7].long_name,
      phone: data.allGooglePlaces.nodes[0].result.international_phone_number,
      lat: data.allGooglePlaces.nodes[0].result.geometry.location.lat,
      lng: data.allGooglePlaces.nodes[0].result.geometry.location.lng,
    },
    openingHours: [
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[0].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[0].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[1].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[1].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[2].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[2].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[3].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[3].close.time.slice(
            0,
            2
          ) + ":00",
      },
      {
        open:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[4].open.time.slice(
            0,
            2
          ) + ":00",
        close:
          data.allGooglePlaces.nodes[0].result.opening_hours.periods[4].close.time.slice(
            0,
            2
          ) + ":00",
      },
    ],
  }

  if (props.title) {
    defaultProps.title = `${props.title} | Dierda Finanzdienstleistung`
  }
  if (props.description) {
    defaultProps.description = `${props.description}`
  }
  if (props.path) {
    defaultProps.path = `${props.path}`
  }

  return (
    <Helmet>
      <html lang={defaultProps.lang} />
      <title>{`${defaultProps.title}`}</title>
      <meta name="description" content={`${defaultProps.description}`} />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={`${defaultProps.title}`} />
      <meta property="og:description" content={`${defaultProps.description}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${defaultProps.title}`} />
      <meta
        name="twitter:description"
        content={`${defaultProps.description}`}
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="theme-color" content="#1939ec" />
      <link rel="canonical" href={`${defaultProps.path}`} />
      <meta
        name="author"
        content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "InsuranceAgency",
            "name": "${defaultProps.adress.name}",
            "image": [
              "${defaultProps.url}${data.img1.childImageSharp.fluid.originalImg}",
              "${defaultProps.url}${data.img2.childImageSharp.fluid.originalImg}",
              "${defaultProps.url}${data.img3.childImageSharp.fluid.originalImg}"
             ],
            "@id": "",
            "url": "${defaultProps.url}",
            "telephone": "${defaultProps.adress.phone}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${defaultProps.adress.street}",
              "addressLocality": "${defaultProps.adress.city}",
              "postalCode": "${defaultProps.adress.plz}",
              "addressCountry": "${defaultProps.adress.countyCode}"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": ${defaultProps.adress.lat},
              "longitude": ${defaultProps.adress.lng}
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Monday",
              "opens": "${defaultProps.openingHours[0].open}",
              "closes": "${defaultProps.openingHours[0].close}"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Tuesday",
              "opens": "${defaultProps.openingHours[1].open}",
              "closes": "${defaultProps.openingHours[1].close}"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Wednesday",
              "opens": "${defaultProps.openingHours[2].open}",
              "closes": "${defaultProps.openingHours[2].close}"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Thursday",
              "opens": "${defaultProps.openingHours[3].open}",
              "closes": "${defaultProps.openingHours[3].close}"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Friday",
              "opens": "${defaultProps.openingHours[4].open}",
              "closes": "${defaultProps.openingHours[4].close}"
            }],
            "sameAs": "${defaultProps.url}",
            "priceRange": "$"
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEO
