import React from "react"
import PropTypes from "prop-types"

const SvgFeature = props => {
  return (
    <div className="flex">
      <div className="w-16 text-center">{props.svg}</div>
      <div className={`w-full ${props.text ? "" : "flex items-center"}`}>
        <h4
          className={`text-sm font-bold  ${
            props.text ? "text-lg mb-2" : "ml-3 text-lg"
          }`}
        >
          {props.title}
        </h4>
        {props.text && <p className="text-sm text-gray-700">{props.text}</p>}
      </div>
    </div>
  )
}

SvgFeature.propTypes = {
  svg: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default SvgFeature
