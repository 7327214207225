import { useEffect } from "react"
import "./KlaroStyles.scss"

const Klaro = () => {
  useEffect(() => {
    try {
      if (typeof window !== `undefined`) {
        window.klaroConfig = {
          elementID: "cookie-consent",
          storageMethod: "cookie",
          cookieName: "cookie-consent",
          cookieExpiresAfterDays: 365,
          //cookieDomain: ".dierda.de",
          default: false,
          mustConsent: false,
          acceptAll: true,
          hideDeclineAll: true,
          hideLearnMore: false,
          privacyPolicy: "/datenschutz",
          htmlTexts: true,
          translations: {
            de: {
              purposes: {
                analytics: "Besucher-Statistiken",
                session: "Sitzungszuordnung",
                advertising: "Anzeigen von Werbung",
              },
              consentModal: {
                title: "Cookie-Einstellungen",
                description:
                  "Wir wollen auf unseren Webseiten und Social Media-Kanälen bestmöglichen Service und Übergreifende Funktionen anbieten sowie Werbung entsprechend der Interessen anzeigen. Dies wird durch die Einbindung von sogenannten Cookies ermöglicht.",
                privacyPolicy: {
                  name: "Datenschutzerklärung",
                  text: "Mehr dazu in der {privacyPolicy}.\n",
                },
              },
              consentNotice: {
                changeDescription:
                  "Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.",
                description: `<p>Wir verwenden Cookies, die für den Betrieb der Webseite notwendig sind und um Ihnen Services anbieten zu können. Zusätzliche Cookies werden nur verwendet, wenn Sie ihnen zustimmen. Diese werden für statistische Zwecke genutzt.</p>`,
                learnMore: "Anpassen & Datenschutz",
              },
              ok: "Akzeptieren",
              acceptSelected: "Auswahl speichern",
              acceptAll: "Allen zustimmen",
              save: "Speichern",
              decline: "Ablehnen",
              close: "Schließen",
              app: {
                disableAll: {
                  title: "Alle Anwendungen aktivieren/deaktivieren",
                  description:
                    "Nutzen Sie diesen Schalter um alle Anwendungen zu aktivieren/deaktivieren.",
                },
                optOut: {
                  title: "(Opt-Out)",
                  description:
                    "Diese Anwendung wird standardmäßig gelanden (aber Sie können sie deaktivieren)",
                },
                required: {
                  title: "(immer notwendig)",
                  description: "Diese Anwendung wird immer benötigt",
                },
                purposes: "Zwecke",
                purpose: "Zweck",
              },
              sas: {
                title: "Werbung",
                description:
                  "Zeige relevante Werbung von unseren Partnern bei SAS, um die Qualität der Webseite zu gewährleisten.",
              },
              googleAnalytics: {
                title: "Google Analytics",
                description:
                  "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots.",
              },
              hotJar: {
                title: "HotJar",
                description:
                  "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots.",
              },
              session: {
                title: "Grundlegende Funktionen",
                description:
                  "Notwendige Cookies, damit Benutzerkonten, Suche und Partner-Anwendungen reibungslos funktionieren und unsere Webseite sicher und vorschriftsmäßig genutzt werden kann.",
              },
              poweredBy: "none",
            },
            en: {
              purposes: {
                analytics: "Besucher-Statistiken",
                session: "Sitzungszuordnung",
                advertising: "Anzeigen von Werbung",
              },
              consentModal: {
                title: "Cookie-Einstellungen",
                description:
                  "Wir wollen auf unseren Webseiten und Social Media-Kanälen bestmöglichen Service und Übergreifende Funktionen anbieten sowie Werbung entsprechend der Interessen anzeigen. Dies wird durch die Einbindung von sogenannten Cookies ermöglicht.",
                privacyPolicy: {
                  name: "Datenschutzerklärung",
                  text: "Mehr dazu in der {privacyPolicy}.\n",
                },
              },
              consentNotice: {
                changeDescription:
                  "Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.",
                description: `<p>Wir verwenden Cookies, die für den Betrieb der Webseite notwendig sind und um Ihnen Services anbieten zu können. Zusätzliche Cookies werden nur verwendet, wenn Sie ihnen zustimmen. Diese werden für statistische Zwecke genutzt.</p>`,
                learnMore: "Anpassen & Datenschutz",
              },
              ok: "Akzeptieren",
              acceptSelected: "Auswahl speichern",
              acceptAll: "Allen zustimmen",
              save: "Speichern",
              decline: "Ablehnen",
              close: "Schließen",
              app: {
                disableAll: {
                  title: "Alle Anwendungen aktivieren/deaktivieren",
                  description:
                    "Nutzen Sie diesen Schalter um alle Anwendungen zu aktivieren/deaktivieren.",
                },
                optOut: {
                  title: "(Opt-Out)",
                  description:
                    "Diese Anwendung wird standardmäßig gelanden (aber Sie können sie deaktivieren)",
                },
                required: {
                  title: "(immer notwendig)",
                  description: "Diese Anwendung wird immer benötigt",
                },
                purposes: "Zwecke",
                purpose: "Zweck",
              },
              sas: {
                title: "Werbung",
                description:
                  "Zeige relevante Werbung von unseren Partnern bei SAS, um die Qualität der Webseite zu gewährleisten.",
              },
              googleAnalytics: {
                title: "Google Analytics",
                description:
                  "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots.",
              },
              hotJar: {
                title: "HotJar",
                description:
                  "Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots.",
              },
              session: {
                title: "Grundlegende Funktionen",
                description:
                  "Notwendige Cookies, damit Benutzerkonten, Suche und Partner-Anwendungen reibungslos funktionieren und unsere Webseite sicher und vorschriftsmäßig genutzt werden kann.",
              },
              poweredBy: "none",
            },
          },

          apps: [
            {
              /*
                              Each app must have a unique name. Klaro will look for HTML elements with a
                              matching 'data-name' attribute to identify elements that belong to this app.
                              */
              name: "googleAnalytics",

              /*
                              If 'default' is set to 'true', the app will be enabled by default. This
                              overrides the global 'default' setting.
                              */
              default: false,

              /*
                              The title of you app as listed in the consent modal. You can also specify
                              translated app titles in the 'translations' section. In that case, you need to
                              leave the title attribute here unspecified.
                              */
              title: "Google Analytics",

              /*
                              The purpose(s) of this app that will be listed on the consent notice. Do not
                              forget to add translations for all purposes you list here.
                              */
              purposes: ["analytics"],

              cookies: [
                /*
                                  you an either only provide a cookie name or regular expression (regex) or a list
                                  consisting of a name or regex, a path and a cookie domain. Providing a path and
                                  domain is necessary if you have apps that set cookies for a path that is not
                                  "/", or a domain that is not the current domain. If you do not set these values
                                  properly, the cookie can't be deleted by Klaro, as there is no way to access the
                                  path or domain of a cookie in JS. Notice that it is not possible to delete
                                  cookies that were set on a third-party domain, or cookies that have the HTTPOnly
                                  attribute: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-
                                  cookie_domain
                                  */

                /*
                                  This rule will match cookies that contain the string '_pk_' and that are set on
                                  the path '/' and the domain 'klaro.kiprotect.com'
                                  */
                [/^_pk_.*$/, "/", "dierda.de"],
              ],

              /*
                              If 'required' is set to 'true', Klaro will not allow this app to be disabled by
                              the user. Use this for apps that are always required for your website to
                              function (e.g. shopping cart cookies).
                              */
              required: false,

              /*
                              If 'optOut' is set to 'true', Klaro will load this app even before the user has
                              given explicit consent. We strongly advise against this.
                              */
              optOut: false,

              /*
                              If 'onlyOnce' is set to 'true', the app will only be executed once regardless
                              how often the user toggles it on and off. This is relevant e.g. for tracking
                              scripts that would generate new page view events every time Klaro disables and
                              re-enables them due to a consent change by the user.
                              */
              onlyOnce: true,
            },
            {
              /*
                              Each app must have a unique name. Klaro will look for HTML elements with a
                              matching 'data-name' attribute to identify elements that belong to this app.
                              */
              name: "hotJar",

              /*
                              If 'default' is set to 'true', the app will be enabled by default. This
                              overrides the global 'default' setting.
                              */
              default: false,

              /*
                              The title of you app as listed in the consent modal. You can also specify
                              translated app titles in the 'translations' section. In that case, you need to
                              leave the title attribute here unspecified.
                              */
              title: "HotJar",

              /*
                              The purpose(s) of this app that will be listed on the consent notice. Do not
                              forget to add translations for all purposes you list here.
                              */
              purposes: ["analytics"],

              cookies: [
                /*
                                  you an either only provide a cookie name or regular expression (regex) or a list
                                  consisting of a name or regex, a path and a cookie domain. Providing a path and
                                  domain is necessary if you have apps that set cookies for a path that is not
                                  "/", or a domain that is not the current domain. If you do not set these values
                                  properly, the cookie can't be deleted by Klaro, as there is no way to access the
                                  path or domain of a cookie in JS. Notice that it is not possible to delete
                                  cookies that were set on a third-party domain, or cookies that have the HTTPOnly
                                  attribute: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-
                                  cookie_domain
                                  */

                /*
                                  This rule will match cookies that contain the string '_pk_' and that are set on
                                  the path '/' and the domain 'klaro.kiprotect.com'
                                  */
                [/^_hj.*$/, "/", "dierda.de"],
              ],

              /*
                              If 'required' is set to 'true', Klaro will not allow this app to be disabled by
                              the user. Use this for apps that are always required for your website to
                              function (e.g. shopping cart cookies).
                              */
              required: false,

              /*
                              If 'optOut' is set to 'true', Klaro will load this app even before the user has
                              given explicit consent. We strongly advise against this.
                              */
              optOut: false,

              /*
                              If 'onlyOnce' is set to 'true', the app will only be executed once regardless
                              how often the user toggles it on and off. This is relevant e.g. for tracking
                              scripts that would generate new page view events every time Klaro disables and
                              re-enables them due to a consent change by the user.
                              */
              onlyOnce: true,
            },
          ],
        }
        loadScript()
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const loadScript = () => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.dataset.config = window.klaroConfig

    script.src = "/scripts/klaro-no-css.js"
    script.defer = true
    document.body.appendChild(script)
  }
}

export default Klaro
